import React from "react";

const TransparentHeader = ({ headertitle, bgimg }) => {
  return (
    <div
      className="breadcrumb-area h-[70vh]"
      style={{ backgroundImage: `url("${bgimg}")` }}
    >
        <div className="text-center relative">
          <h1 className="text-white">{headertitle}</h1>
        </div>
    </div>
  );
};

export default TransparentHeader;
