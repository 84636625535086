import React, { useContext } from "react";
import { FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { ButtonContent } from '../global/boton/ButtonContent'
import { BiPhoneCall, BiCalendar } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaRegClock } from 'react-icons/fa'
import SocialMedia from "../global/SocialMedia";
import CounterUp from '../global/CounterUp'
import Map from "../Contact/MapContent";

function ServicesDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const imgHeader = [
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-03T18%3A33%3A59.890Z-14.jpeg?alt=media&token=20b5ae77-750f-4bf0-8e42-8acf37d63f04`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.933Z-41.jpg?alt=media&token=3c92cb28-0f40-42ee-8d6e-1a027a1f15fe`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-14T21%3A28%3A55.013Z-2.jpeg?alt=media&token=8f628a98-b72e-46fe-838c-73c3327cb806`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A07%3A00.463Z-20.jpg?alt=media&token=3ad0c796-4469-4ec9-9fff-964e83534ac1`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.932Z-18.jpg?alt=media&token=d3feaefb-9404-494d-817c-8e2538412587`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A06%3A23.792Z-40.jpg?alt=media&token=36ca5774-7912-4f84-b278-0319d803f12c`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A06%3A23.792Z-42.jpg?alt=media&token=48f18934-029e-4397-a227-370d36351475`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A07%3A00.461Z-0.jpg?alt=media&token=58301268-8fb4-4489-9ffd-b34f0607762f`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.931Z-5.jpg?alt=media&token=5e555a6a-b3ca-4dcf-a7bf-53cf435bdffc`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.932Z-14.jpg?alt=media&token=ed45487a-58bd-46dd-879a-353d6c94bdde`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fgallery%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-01T20%3A33%3A56.436Z-10.JPG?alt=media&token=e080bcd1-58c3-4a8e-8a2a-ef24f5dbbac7`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.931Z-6.jpg?alt=media&token=b4fa8469-8b73-4562-80a5-90917783015d`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A07%3A00.462Z-2.jpg?alt=media&token=bab88921-df5a-4a8e-b9be-bddcf151a6be`,
    ]

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.dbServices?.map((item, index) => {
                    if (item.name.replace(" ", "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    bgimg={imgHeader[index]}
                                    Subheader={item.name}
                                />
                                <div className="w-[95%] md:w-[90%] mx-auto my-10 block md:flex md:gap-5">
                                    <div className="w-full md:w-[70%] shadow-lg shadow-neutral-800/30 p-5 md:p-10 mb-5">
                                        <img
                                            src={item.description[0].img}
                                            alt='no found'
                                            className="w-full object-cover h-auto md:h-[500px]"
                                        />
                                        <h3>
                                            {
                                                rpdata?.dbSlogan[Math.floor(Math.random() * rpdata?.dbSlogan?.length)].slogan
                                            }
                                        </h3>
                                        {
                                            item.description.map((item, index) => {
                                                return (
                                                    <p key={index}>{item.text} <br /> <br /></p>

                                                )
                                            })
                                        }
                                        <ButtonContent />
                                    </div>
                                    <div className="w-full md:w-[30%] shadow-lg bg-[#1F8B8B] p-5 md:p-10 mb-5">
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start text-white"> Our Services</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbServices?.slice(0, 7).map((nam, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link
                                                                    to={`/${nam.name.replace(" ", "-").toLowerCase()}`}
                                                                    onClick={goToTop}
                                                                    className='flex items-center text-white pb-3'
                                                                >
                                                                    <FaCaretRight />
                                                                    <span className="pl-2">
                                                                        {nam.name}
                                                                    </span>
                                                                </Link>
                                                            </li>

                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start text-white">Contact</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                        return (
                                                            <li key={index} className='flex items-center text-white pb-3'>
                                                                <a
                                                                    href={`tel:+1${phone.phone}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <BiPhoneCall />
                                                                    <span className="pl-2">
                                                                        {phone.phone}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                                {
                                                    rpdata?.dbPrincipal?.emails.map((email, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className='flex items-center text-white pb-3'
                                                            >
                                                                <a
                                                                    href={`mailto:${email.email}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <MdOutlineEmail />
                                                                    <span className="pl-2">
                                                                        {email.email}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start text-white">Opening Hours</h4>
                                            <ul className="px-1 md:px-4">
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <BiCalendar />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                                    </span>
                                                </li>
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <FaRegClock />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            rpdata?.dbSocialMedia?.redes?.[0].length > 1 ?
                                                <div className="pb-5">
                                                    <h4 className="text-center md:text-start">Follow Us</h4>
                                                    <SocialMedia />
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }
            <CounterUp />
            <Map />

        </BaseLayout>
    );
}

export default ServicesDetail;