import React from "react";
import Navmenu from "./NavMenu";

const HeaderOne = () => {

  return (
    <div>
      <div className="z-50 pt-3 w-full">
        <Navmenu />
      </div>
    </div>
  );
};

export default HeaderOne;
