import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/ValuesOne";
import BlockFour from "../components/block/BlockFour";
import BlockTwo from "../components/block/BlockOne";
import BlockFive from "../components/block/BlockFive";
import BlockSix from "../components/block/BlockSix";
import BlockThree from "../components/block/BlockThree";
import BlockSeven from "../components/block/BlockSeven";
import BlockEight from "../components/block/BlockEight";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle=""
          Subheader="About"
          bgimg={`${rpdata?.stock?.[22]}`}
        />
        <BlockFour
          image1={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.932Z-31.jpg?alt=media&token=52627703-04b1-4a0b-b1eb-cb29aecfad92`}
          image2={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.932Z-20.jpg?alt=media&token=8a6c3e7d-64fa-4911-b809-0685deaf68e7`}
          image3={rpdata?.stock?.[24]}
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbAbout?.[0]?.text}
          listAbout={true}
        />
        <ValuesOne image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A07%3A00.463Z-16.jpg?alt=media&token=db490827-681b-46bd-a3f6-ca26daa4f3d6`} />
        <BlockThree
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A07%3A00.464Z-61.jpg?alt=media&token=58ddd63f-75b3-4a43-b611-4699470ddb93`}
        />
        <BlockTwo
          image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A06%3A23.791Z-1.jpg?alt=media&token=b21cfc70-d168-4086-8170-e3c10d5e9b31`}
          title={`HOME BUILDERS ASSOCIATES CORP WE ARE IN THE HAMPTONS NY`}
          listAbout={false}
        />
        <BlockFive
          image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-14T21%3A28%3A55.013Z-1.jpeg?alt=media&token=03b98d62-a4d4-4d14-8f87-dcdbb34cca1f`}
          title={rpdata?.dbSlogan?.[5]?.slogan}
          listAbout={false}
        />
         <BlockSix
          image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.931Z-2.jpg?alt=media&token=d288e4b4-d292-4226-af46-c3ccd896a6ef`}
          title={rpdata?.dbSlogan?.[2]?.slogan}
          listAbout={false}
        />
         <BlockSeven
          image={rpdata?.stock?.[23]}
          title={rpdata?.dbSlogan?.[6]?.slogan}
          listAbout={false}
        />
        <BlockEight
          image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A07%3A00.462Z-14.jpg?alt=media&token=60640ff6-721c-4bc2-83e6-29e97b194065`}
          title={rpdata?.dbSlogan?.[5]?.slogan}
          listAbout={false}
        />
      </div>
    </BaseLayout>
  );
}

export default About;
