import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ServicesHome_3";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockTwo from "../components/block/BlockTwo";
import BlockThree from "../components/block/BlockThree";
import BlockOne from "../components/block/BlockOne";
import ValuesOne from "../components/values/Values_2";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Home">
      <HeroSection />
      <div className="md:max-w-full w-full">
        <Modal />
        <BlockOne
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbHome?.[0].text}
          image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A06%3A23.792Z-39.jpg?alt=media&token=b46c6850-8af6-468c-a8ea-661ab9d041bc`}
          sloganPrincipal={false}
          listsServices={true}
        />
        <div className="bg1">
          <div className="md:w-4/5 w-[95%] mx-auto flex flex-col text-center py-20">
            <span className="md:text-[50px] text-[30px] font-bold text-white">
              Licenses. And Insurance For Home builders Associates Corp
            </span>
            <span className="text-[20px] text-white py-2">Licensed in Suffolk County</span>
            <span className="text-[20px] text-white py-2">
              Licensed in Southampton NY (inclusive of Southampton Village,
              Bridgehampton, Sag Harbor, Quogue, North Haven, and Westhampton)
            </span>
            <span className="text-[20px] text-white py-2">
              Licensed in East Hampton NY (East Hampton Village, Amagansett,
              Wainscott, Sag Harbor, Montauk) Work is guaranteed and covered by
              workers' and owners' life insurance.
            </span>
          </div>
        </div>

        <Directories />
        <BlockTwo
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[0]?.text}
          image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.932Z-39.jpg?alt=media&token=54aa0bc2-74ed-4032-a067-a5bbaa33b641`}
          sloganPrincipal={false}
          listsServices={true}
        />
        <CounterUp image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-03T18%3A33%3A59.890Z-9.jpeg?alt=media&token=a65fffbb-f1c8-465b-954e-4dffe8ab1705`} />
        <ValuesOne image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A06%3A23.792Z-34.jpg?alt=media&token=91bd3026-8179-439c-a230-130e343a66af`} />
        <BlockThree
          title={rpdata?.dbSlogan?.[4]?.slogan}
          text={rpdata?.dbAbout?.[0]?.text}
          image={`https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A06%3A23.791Z-1.jpg?alt=media&token=b21cfc70-d168-4086-8170-e3c10d5e9b31`}
          listsAbout={true}
        />
        <ServicesHome />
        {/* Paleta de Colores */}
        {rpdata?.brandingExtra?.[0]?.activo ? <Paletacolor /> : null}
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A07%3A00.462Z-6.jpg?alt=media&token=3a020610-61be-4737-967d-3aced3ebbffc)` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
