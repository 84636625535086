import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-14T21%3A28%3A55.011Z-0.jpeg?alt=media&token=1490d8bd-c7e2-441c-b8af-5b6337cc072f")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-14T21%3A28%3A55.013Z-1.jpeg?alt=media&token=03b98d62-a4d4-4d14-8f87-dcdbb34cca1f")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-14T21%3A28%3A55.014Z-3.jpeg?alt=media&token=65ba7f52-30bf-40e9-8708-9a7b7ee41171")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-14T21%3A28%3A55.013Z-2.jpeg?alt=media&token=8f628a98-b72e-46fe-838c-73c3327cb806")` }}
        ></figure>
        <div className="z-10 relative md:pt-[280px] pt-[280px] pb-[150px] w-4/5 mx-auto">
          <div className="w-full flex flex-col justify-center items-center h-[35vh]">
            <h1 className="text-white text-center">
            </h1>
            <p className="text-white text-center"></p>
            <div className="flex justify-center md:block">
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
