import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const BlockSeven = ({ title, sloganPrincipal, image }) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div className="bg-[#efefef] py-[150px]">
      <div className="w-[95%] md:w-4/5 mx-auto">
        <div className="border-[15px] borderColor flex flex-col-reverse md:flex-row px-5 md:px-10">
          <div className="w-full md:w-1/2 ml-0 -mt-20 mb-0 md:ml-2 md:-my-20">
            <img
              src={image}
              alt="no found"
              className="w-full h-[300px] md:h-full object-cover"
            />
          </div>
          <div className="w-full md:w-1/2 bg-white mr-0 -mb-20 mt-5 md:mr-2 md:-my-20 px-10 py-12 text-center md:text-start">
            {sloganPrincipal ? (
              rpdata?.dbPrincipal?.licensed.length > 1 ? (
                <h2 className="pb-3 capitalize">
                  {rpdata?.dbPrincipal?.licensed}
                </h2>
              ) : (
                <h2 className="pb-3 capitalize">
                  we have {rpdata?.dbPrincipal?.exprYears} years of experience{" "}
                </h2>
              )
            ) : (
              <h2 className="pb-3 capitalize">
                {title ? (
                  title
                ) : (
                  <span className="lowercase text-[18px]">
                    {`default title={'frase'}`} o
                    {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                  </span>
                )}
              </h2>
            )}
            <p className="pb-3">{rpdata?.dbAbout?.[8]?.text}</p>
            <p className="pb-3">{rpdata?.dbAbout?.[9]?.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockSeven;
