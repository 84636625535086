import React, { useContext } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";



function GalleryDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);

    const imgHeader = [
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.932Z-32.jpg?alt=media&token=50922c3c-a337-40bd-ae83-bd003fb876f2`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A06%3A23.792Z-39.jpg?alt=media&token=b46c6850-8af6-468c-a8ea-661ab9d041bc`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.932Z-33.jpg?alt=media&token=71455c0b-5cac-4e76-b2cd-1a051570242c`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A07%3A00.464Z-61.jpg?alt=media&token=58ddd63f-75b3-4a43-b611-4699470ddb93`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fgallery%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-01T20%3A33%3A04.749Z-51.JPG?alt=media&token=55c47624-d56d-422d-8c67-6e58ae242be5`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A06%3A23.792Z-40.jpg?alt=media&token=36ca5774-7912-4f84-b278-0319d803f12c`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.931Z-6.jpg?alt=media&token=b4fa8469-8b73-4562-80a5-90917783015d`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A05%3A47.932Z-14.jpg?alt=media&token=ed45487a-58bd-46dd-879a-353d6c94bdde`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fgallery%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-01T20%3A33%3A56.436Z-10.JPG?alt=media&token=e080bcd1-58c3-4a8e-8a2a-ef24f5dbbac7`,
        `https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F633c524bf4390f8712664e74%2Fstock%2FHOME%20BUILDERS%20ASSOCIATES%20CORP-2022-11-16T18%3A07%3A00.463Z-18.jpg?alt=media&token=6b5d5df2-324b-49e2-9ac9-a2e25d68b29d`,
    ]

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.landings?.map((item, index) => {
                    if (item.name.replaceAll(" ", "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    // imagen aleatoria
                                    bgimg={imgHeader[index]}
                                    Subheader={'Our Projects'}
                                />
                                <>
                                    <div className="w-[90%] mx-auto text-center pt-[100px] pb-[80px]">
                                    <h2 className="pb-5 ">our recent projects of:</h2>
                                    <h2>{item.name}</h2>
                                    </div>
                                    <div className="pb-[100px] flex justify-center">
                                        <div className="gallery-content w-4/5 mx-auto">
                                            <Gallery>
                                                {
                                                    item?.gallery.map((item, index) => (
                                                        <Item
                                                            key={index}
                                                            original={item}
                                                            thumbnail={item}
                                                            width="1024"
                                                            height="550"
                                                            padding="10px"
                                                        >
                                                            {({ ref, open }) => <img ref={ref} onClick={open} src={item} alt="Not Found" />}
                                                        </Item>
                                                    ))
                                                }
                                            </Gallery>
                                        </div>
                                    </div>
                                </>
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }


        </BaseLayout>

    )
}

export default GalleryDetail;